
import { Vue, Component } from "vue-property-decorator";
import { mapMutations, mapGetters } from "vuex";
import { ResponseDataStringArray as rdsa, Chart } from "@/interfaces";
import * as types from "@/store/types";
import { Eoption } from "@/utils";
@Component({
    components: {
        FilterFormPanelBase: () =>
            import("@/components/layout/FilterFormPanelBase.vue"),
        CardWidget: () => import("@/components/layout/CardWidget.vue"),
        PlotlyChart: () => import("@/components/charts/PlotlyChart.vue"),
        CalendarInput: () => import("@/components/layout/CalendarInput.vue"),
        Echart: () => import("@/components/charts/eChart.vue")
    },
    methods: {
        ...mapMutations({
            setProgressBar: types.MUTATE_APP_PROGRESSBAR,
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE
        })
    },
    computed: {
        ...mapGetters({
            userIdentifier: types.USER_IDENTIFIER
        })
    }
})
export default class Saving extends Vue {
    setProgressBar!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;
    showError(error: string) {
        this.setInfoMessage({ shown: true, text: error });
    }
    showFilter = true;
    noSummoned = true;
    role = Vue.prototype.$user.get().role;
    userIdentifier?: number;
    companies: string[] = [];
    company = "";
    clients: string[] = [];
    client = "";
    install = 0;
    rules = {
        required: value => !!value || "Requerido",
        counter: value => value.length <= 6 || "Maximo 6 dígitos",
        days: value => !!Number(value) || "No es un número"
    };
    showAll = false;
    Pmodo = "Simulado";
    Nproyecto = 0;
    Nproyectolinea = 0;
    Nrevisio = 0;
    Ndocumen = 0;
    dialog = false;
    dialogDelete = false;
    dialogDeleteProject = false;
    project1Chart: Chart = { data: [] };
    project2Chart: Chart = { data: [] };
    Titulo = "";
    Descripcion = "";
    Instalacion = "";
    Elemento = "";
    valObj = "0";
    Comm = "";
    AP = "0";
    ref = "";
    period = "";
    overlay = false;
    loading = 0;
    ahorro = 0;
    color = "white";
    cabecera = [
        {
            text: "Fecha Inicio",
            align: "start",
            value: "Finicio"
        },
        {
            text: "Fecha Final",
            align: "start",
            value: "Ffinal"
        },
        {
            text: "Nombre",
            align: "start",
            value: "Titulo"
        },
        {
            text: "Descripción",
            align: "start",
            value: "Descripcion"
        },
        {
            text: "Instalación/Elemento",
            align: "start",
            value: "Elemento"
        }
    ];
    proyectos: any[] = [];
    headers = [
        {
            text: "Inicio",
            align: "start",
            value: "Finicio"
        },
        {
            text: "Final",
            align: "start",
            value: "Ffinal"
        },
        { text: "Coste Objetivo", value: "valObj" },
        { text: "Coste", value: "coste" },
        { text: "Consumo", value: "consumo" },
        { text: "Acciones Planificadas", value: "AP" },
        { text: "Comentarios", value: "Comm" },
        { text: "", value: "actions", sortable: false }
    ];
    desserts: any = [];
    editedIndex = -1;
    editedItem = {
        Finicio: "",
        Ffinal: "",
        Npl: "0",
        valObj: "0",
        Comm: "",
        AP: ""
    };
    defaultItem = {
        Finicio: "",
        Ffinal: "",
        Npl: "0",
        valObj: "0",
        Comm: "",
        AP: "0"
    };

    beforeCreate() {
        this.$root.$on("Checked", (proyecto, company, client) => {
            this.noSummoned = false;
            this.company = company;
            this.client = client;
            this.fetchProject();
            this.fetchProjectLine(proyecto);
        });
    }

    mounted() {
        // eslint-disable-next-line
        this.$root.$on("Saving", () => {
            this.fetchUser();
        }); // eslint-disable-next-line

        if (this.noSummoned) {
            this.fetchUser();
        }
    }

    formTitle(value) {
        return this.editedIndex === -1 ? "Nueva Línea" : value;
    }

    watch = {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        }
    };

    editItem(item) {
        this.editedIndex = this.desserts.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
    }

    deleteItem(item) {
        this.editedIndex = this.desserts.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
        this.Nproyectolinea = this.desserts[this.editedIndex]["Npl"];
    }

    deleteItemConfirm() {
        this.desserts.splice(this.editedIndex, 1);
        this.deleteProjectLine();
        this.closeDelete();
    }

    close() {
        this.dialog = false;
        this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
        });
    }

    closeDelete() {
        this.dialogDeleteProject = false;
        this.dialogDelete = false;
        this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
        });
    }

    save() {
        if (this.editedIndex > -1) {
            Object.assign(this.desserts[this.editedIndex], this.editedItem);
        } else {
            this.desserts.push(this.editedItem);
        }
        this.Nproyectolinea = this.desserts[this.editedIndex]["Npl"];
        this.upgradeProjectLine();
        this.close();
    }

    async fetchUser() {
        if (this.role == "client") {
            this.setProgressBar(true);
            try {
                const userClientResponse = await this.$api.SQLuserClient<rdsa>(
                    this.userIdentifier
                );
                if (userClientResponse.ok) {
                    this.company = userClientResponse.data[0];
                    this.client = userClientResponse.data[1];
                    this.fetchProject();
                }
            } catch (error) {
                if (error instanceof Error) {
                    this.showError(error.message);
                }
            } finally {
                this.setProgressBar(false);
            }
        } else if (this.role == "admin") {
            this.fetchCompany();
        } else {
            console.log("Role undefined");
        }
    }

    async fetchCompany() {
        this.setProgressBar(true);
        try {
            const companyResponse = await this.$api.SQLcompany<rdsa>();
            if (companyResponse.ok) {
                this.companies = companyResponse.data;
                this.company = this.companies[0];
                this.fetchClient();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setProgressBar(false);
        }
    }

    async fetchClient() {
        this.setProgressBar(true);
        this.reset0();
        try {
            const clientResponse = await this.$api.SQLclient<rdsa>(
                this.company.split(" - ")[0]
            );
            if (clientResponse.ok) {
                this.clients = clientResponse.data;
                this.client = this.clients[0];
                this.fetchProject();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setProgressBar(false);
        }
    }

    async fetchProject() {
        this.reset1();
        this.setProgressBar(true);
        try {
            const projectResponse = await this.$api.getProject<rdsa>(
                this.client.split(" - ")[0]
            );
            if (projectResponse.ok) {
                this.proyectos = this.formatDate(projectResponse.data);
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setProgressBar(false);
        }
    }

    async fetchProjectLine(proyecto) {
        this.reset2();
        this.setProgressBar(true);
        try {
            this.Nproyecto = proyecto.Nproyecto;
            this.install = proyecto.Ninstala;
            this.Nrevisio = proyecto.Nrevisio;
            this.Ndocumen = proyecto.Ndocumen;
            if (proyecto.Periodo == 1) {
                this.period = "Diario";
            } else if (proyecto.Periodo == 2) {
                this.period = "Semanal";
            } else if (proyecto.Periodo == 3) {
                this.period = "Mensual";
            } else {
                this.period = "Seguimiento";
            }
            this.ref =
                "https://sat.euskabea.com/files/documentos/" +
                String(this.Ndocumen) +
                "_1.pdf";
            const PLResponse = await this.$api.getProjectLineService<rdsa>(
                proyecto.Nproyecto
            );
            if (PLResponse.ok) {
                if (PLResponse.data.length != 0) {
                    this.showAll = true;
                    this.desserts = this.formatDate(
                        PLResponse.data["info"][0]["data"]
                    );
                    this.Titulo = PLResponse.data["info"][0]["Titulo"];
                    this.Descripcion =
                        PLResponse.data["info"][0]["Descripcion"];
                    this.Pmodo = PLResponse.data["info"][0]["Pmodo"];
                    this.Instalacion =
                        PLResponse.data["info"][0]["Instalacion"];
                    this.Elemento = PLResponse.data["info"][0]["Elemento"];
                    this.project1Chart = {
                        ...Eoption,
                        ...PLResponse.data["coste"]
                    };
                    // this.project2Chart = PLResponse.data["ahorro"];
                    this.project2Chart = {
                        ...Eoption,
                        ...PLResponse.data["ahorro"]
                    };
                    this.ahorro = PLResponse.data["acumulado"];
                    if (Number(this.ahorro) > 0) {
                        this.color = "green " + this.color;
                    } else {
                        this.color = "red " + this.color;
                    }
                }
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setProgressBar(false);
        }
    }

    async genProject() {
        this.overlay = true;
        this.loading = 5;
        try {
            const PLResponse = await this.$api.generateProject<rdsa>(
                this.install,
                this.Nrevisio
            );
            if (PLResponse.ok) {
                this.showError("Proyecto generado en el área clientes.");
                this.Ndocumen = Number(PLResponse.data[0]);
                this.ref =
                    "https://sat.euskabea.com/files/documentos/" +
                    String(this.Ndocumen) +
                    "_1.pdf";
                this.overlay = false;
                this.loading = 0;
            }
        } catch (error) {
            if (error instanceof Error) {
                this.overlay = false;
                this.loading = 0;
                this.showError(error.message);
            }
        }
    }

    formatDate(data) {
        for (const pl of data) {
            pl.Ffinal = new Date(pl.Ffinal).toISOString().split(".")[0];
            let aux = pl.Ffinal.split("T");
            pl.Ffinal = aux[0] + " " + aux[1];
            pl.Finicio = new Date(pl.Finicio).toISOString().split(".")[0];
            aux = pl.Finicio.split("T");
            pl.Finicio = aux[0] + " " + aux[1];
        }
        return data;
    }

    async deleteProjectLine() {
        this.setProgressBar(true);
        try {
            const deleteResponse = await this.$api.deleteProjectLine<rdsa>(
                this.Nproyectolinea
            );
            if (deleteResponse.ok) {
                this.showError("Linea de Proyecto Eliminada");
                this.Nproyectolinea = 0;
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setProgressBar(false);
        }
    }

    async deleteProject() {
        this.setProgressBar(true);
        /*Cuando se borra un proyecto, tambien eliminarlo de la lista de proyectos*/
        try {
            const deleteResponse = await this.$api.deleteProject<rdsa>(
                this.Nproyecto,
                this.Nrevisio
            );
            if (deleteResponse.ok) {
                this.dialogDeleteProject = false;
                this.showError("Proyecto Eliminado");
                this.Nproyecto = 0;
                this.install = 0;
                this.Nrevisio = 0;
                this.Ndocumen = 0;
                this.desserts = [];
                this.Titulo = "";
                this.Descripcion = "";
                this.Instalacion = "";
                this.Elemento = "";
                this.fetchProject();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    async upgradeProjectLine() {
        this.setProgressBar(true);
        try {
            const upgradeResponse = await this.$api.updateProjectLine<rdsa>(
                this.Nproyectolinea,
                this.editedItem
            );
            if (upgradeResponse.ok) {
                this.showError("Linea de Proyecto Editada");
                this.Nproyectolinea = 0;
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setProgressBar(false);
        }
    }

    goTo() {
        window.location.replace("/#/form");
    }

    dosomething() {
        this.dialogDeleteProject = true;
    }

    reset0() {
        this.clients = [];
        this.client = "";
        this.color = "white";
        this.ahorro = 0;
        this.Nproyecto = 0;
        this.Nproyectolinea = 0;
        this.Nrevisio = 0;
        this.Ndocumen = 0;
        this.dialog = false;
        this.dialogDelete = false;
        this.dialogDeleteProject = false;
        this.project1Chart = { data: [] };
        this.project2Chart = { data: [] };
        this.Titulo = "";
        this.Descripcion = "";
        this.Instalacion = "";
        this.Elemento = "";
        this.valObj = "0";
        this.Comm = "";
        this.AP = "0";
        this.Pmodo = "Simulado";
        this.ref = "";
        this.overlay = false;
        this.loading = 0;
        this.proyectos = [];
        this.desserts = [];
        this.editedIndex = -1;
        this.editedItem = {
            Finicio: "",
            Ffinal: "",
            Npl: "0",
            valObj: "0",
            Comm: "",
            AP: ""
        };
    }

    reset1() {
        this.color = "white";
        this.ahorro = 0;
        this.Nproyecto = 0;
        this.Nproyectolinea = 0;
        this.Nrevisio = 0;
        this.Ndocumen = 0;
        this.dialog = false;
        this.dialogDelete = false;
        this.dialogDeleteProject = false;
        this.project1Chart = { data: [] };
        this.project2Chart = { data: [] };
        this.Titulo = "";
        this.Descripcion = "";
        this.Instalacion = "";
        this.Elemento = "";
        this.valObj = "0";
        this.Comm = "";
        this.AP = "0";
        this.ref = "";
        this.Pmodo = "Simulado";
        this.overlay = false;
        this.loading = 0;
        this.desserts = [];
        this.editedIndex = -1;
        this.editedItem = {
            Finicio: "",
            Ffinal: "",
            Npl: "0",
            valObj: "0",
            Comm: "",
            AP: ""
        };
    }

    reset2() {
        this.color = "white";
        this.Pmodo = "Simulado";
        this.ahorro = 0;
        this.Nproyectolinea = 0;
        this.dialog = false;
        this.dialogDelete = false;
        this.dialogDeleteProject = false;
        this.overlay = false;
        this.loading = 0;
    }
}
